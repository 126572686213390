$(document).on("turbolinks:load", function() {
    $('.check input[value="06.00 WIB"]').click(function() {
        $('.check input[value="06.00 WIB"]').not(this).prop('checked', false);
    }); 
    $('.check input[value="07.00 WIB"]').click(function() {
        $('.check input[value="07.00 WIB"]').not(this).prop('checked', false);
    }); 
    $('.check input[value="08.00 WIB"]').click(function() {
        $('.check input[value="08.00 WIB"]').not(this).prop('checked', false);
    }); 
    $('.check input[value="09.00 WIB"]').click(function() {
        $('.check input[value="09.00 WIB"]').not(this).prop('checked', false);
    }); 
    $('.check input[value="10.00 WIB"]').click(function() {
        $('.check input[value="10.00 WIB"]').not(this).prop('checked', false);
    }); 
    $('.check input[value="11.00 WIB"]').click(function() {
        $('.check input[value="11.00 WIB"]').not(this).prop('checked', false);
    }); 
    $('.check input[value="12.00 WIB"]').click(function() {
        $('.check input[value="12.00 WIB"]').not(this).prop('checked', false);
    }); 
    $('.check input[value="13.00 WIB"]').click(function() {
        $('.check input[value="13.00 WIB"]').not(this).prop('checked', false);
    }); 
    $('.check input[value="14.00 WIB"]').click(function() {
        $('.check input[value="14.00 WIB"]').not(this).prop('checked', false);
    }); 
    $('.check input[value="15.00 WIB"]').click(function() {
        $('.check input[value="15.00 WIB"]').not(this).prop('checked', false);
    });  

    $('.check input[value="16.00 WIB"]').click(function() {
        $('.check input[value="16.00 WIB"]').not(this).prop('checked', false);
    }); 
    $('.check input[value="17.00 WIB"]').click(function() {
        $('.check input[value="17.00 WIB"]').not(this).prop('checked', false);
    }); 
    $('.check input[value="18.00 WIB"]').click(function() {
        $('.check input[value="18.00 WIB"]').not(this).prop('checked', false);
    }); 
    $('.check input[value="19.00 WIB"]').click(function() {
        $('.check input[value="19.00 WIB"]').not(this).prop('checked', false);
    }); 
    $('.check input[value="20.00 WIB"]').click(function() {
        $('.check input[value="20.00 WIB"]').not(this).prop('checked', false);
    }); 
    $('.check input[value="21.00 WIB"]').click(function() {
        $('.check input[value="21.00 WIB"]').not(this).prop('checked', false);
    }); 
    $('.check input[value="22.00 WIB"]').click(function() {
        $('.check input[value="22.00 WIB"]').not(this).prop('checked', false);
    }); 
    $('.check input[value="23.00 WIB"]').click(function() {
        $('.check input[value="23.00 WIB"]').not(this).prop('checked', false);
    }); 
    $('.check input[value="24.00 WIB"]').click(function() {
        $('.check input[value="24.00 WIB"]').not(this).prop('checked', false);
    }); 
    $('.check input[value="01.00 WIB"]').click(function() {
        $('.check input[value="01.00 WIB"]').not(this).prop('checked', false);
    }); 
    $('.check input[value="02.00 WIB"]').click(function() {
        $('.check input[value="02.00 WIB"]').not(this).prop('checked', false);
    }); 
    $('.check input[value="03.00 WIB"]').click(function() {
        $('.check input[value="03.00 WIB"]').not(this).prop('checked', false);
    }); 
    $('.check input[value="04.00 WIB"]').click(function() {
        $('.check input[value="04.00 WIB"]').not(this).prop('checked', false);
    }); 
    $('.check input[value="05.00 WIB"]').click(function() {
        $('.check input[value="05.00 WIB"]').not(this).prop('checked', false);
    }); 

})