import AutoNumeric from 'autonumeric';

$(document).on('change',function() {
        const makeRupiah = {
            allowDecimalPadding: false,
            currencySymbol: "Rp.",
            decimalCharacter: ",",
            decimalPlaces: 0,
            decimalPlacesRawValue: 0,
            decimalPlacesShownOnBlur: 0,
            decimalPlacesShownOnFocus: 0,
            digitGroupSeparator: ".",
            historySize: 9007199254740991,
            minimumValue: "0",
            negativeSignCharacter: "−",
            noEventListeners: true,
            outputFormat: "number",
            roundingMethod: "U",
            unformatOnSubmit: true
          };
        //   anElement = new AutoNumeric.multiple('.autorupiah', makeRupiah);
});

// $('form').submit(function(){
//     var form = $(this);
//     $('input').each(function(i){
//         var self = $(this);
//         try{
//             var v = self.autoNumeric('get');
//             self.autoNumeric('destroy');
//             self.val(v);
//         }catch(err){
//             console.log("Not an autonumeric field: " + self.attr("name"));
//         }
//     });
//     return true;
// });