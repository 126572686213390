document.addEventListener('turbolinks:load', () => {
  $(document).ready(function() {
    $('.mine_product-select').select2({sorter: data => data.sort((a, b) => a.text.localeCompare(b.text)),placeholder: "Select Unit Type",
      allowClear: true});
    $('.customer-select').select2({sorter: data => data.sort((a, b) => a.text.localeCompare(b.text)),placeholder: "Select Unit Type",
      allowClear: true});
    $('.op-select').select2({
      sorter: data => data.sort((a, b) => a.text.localeCompare(b.text)), placeholder: "Select Operator ",
      allowClear: true
    });
    $('.unittype-select').select2({
      sorter: data => data.sort((a, b) => a.text.localeCompare(b.text)),placeholder: "Select Unit Type",
      allowClear: true
    });
    $('.unitact-select').select2({
      sorter: data => data.sort((a, b) => a.text.localeCompare(b.text)),placeholder: "Select Unit Activity",
      allowClear: true
    });
    $('.fuel-select').select2({
      sorter: data => data.sort((a, b) => a.text.localeCompare(b.text)),placeholder: "Select Fuel",
      allowClear: true
    });
    $('.unit-select').select2({
      sorter: data => data.sort((a, b) => a.text.localeCompare(b.text)),placeholder: "Select Unit",
      allowClear: true
    });
    $('.org-select').select2({
      sorter: data => data.sort((a, b) => a.text.localeCompare(b.text)),placeholder: "Select Organization",
      allowClear: true
    });
    $('.actdetails-select').select2({
      sorter: data => data.sort((a, b) => a.text.localeCompare(b.text)),placeholder: "Select Activity Details",
      allowClear: true
    });
    $('.coa-select').select2({
      sorter: data => data.sort((a, b) => a.text.localeCompare(b.text)),placeholder: "Select COA",
      allowClear: true
    });

    $('.cost_code-select').select2({
      sorter: data => data.sort((a, b) => a.text.localeCompare(b.text)),placeholder: "Select Cost Code",
      allowClear: true
    });

    $('.comp_code-select').select2({
      sorter: data => data.sort((a, b) => a.text.localeCompare(b.text)),placeholder: "Select Component Code",
      allowClear: true
    });
    
    $('.employee-select').select2({
      sorter: data => data.sort((a, b) => a.text.localeCompare(b.text)),placeholder: "Select Employee",
      allowClear: true
    });
    
    $('.company-select').select2({
      sorter: data => data.sort((a, b) => a.text.localeCompare(b.text)),placeholder: "Select Company",
      allowClear: true
    });

    $('.comp-select').select2({
      sorter: data => data.sort((a, b) => a.text.localeCompare(b.text)),placeholder: "Select Component",
      allowClear: true
    });

    $('.cat-select').select2({
      sorter: data => data.sort((a, b) => a.text.localeCompare(b.text)),placeholder: "Select Category",
      allowClear: true
    });

    $('.site-select').select2({
      sorter: data => data.sort((a, b) => a.text.localeCompare(b.text)),placeholder: "Select Mine Block",
      allowClear: true
    });


  });
});