document.addEventListener('turbolinks:load', () => {
    $(document).ready(function() {

        var attTable = $('#att-table').DataTable({
            "autoWidth": true,
            "processing": true,
            "serverSide": true,
            "ajax": {
                "url": $('#att-table').data('source'),
                type: 'POST'
            },
            "columns": [
                {"data": "date"},
                {"data": "employee"},
                {"data": "clock_in"},
                {"data": "clock_out"},
                {"data": "overtime_start"},
                {"data": "overtime_end"},
                {"data": "state"},
                {"data": "description"},
                {"data": "dt_actions"}
            ],    
            "scrollY":"480px",
            "scrollX": true,
            "columnDefs" : [{"targets":0, "type":"date-eu"},{
                "defaultContent": "-",
                "targets": "_all"
            }],
            "order": [0, 'desc'],
            "lengthMenu": [[30, 60, 90, -1], [30, 60, 90, "All"]],
            "dom": "<'row'<'form-control-md col-sm-9'f><'col-sm-3' <'#dateContainer'>>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-5'l><'col-sm-7'B>>" +
            "<'row'<'col-sm-6'i><'col-sm-6'p>>",
            "buttons": { buttons: [{ extend: 'copy', className: 'copyButton' }, { extend: 'csv', className: 'csvButton', header: true }, { extend: 'print', className: 'printButton', customize: function ( win ) {
                $(win.document.body)
                    .css( 'font-size', '10pt' )
                    .prepend(
                        '<img src="/app/assets/images/geopedialogo.png" style="position:absolute; top:0; left:0;" />'
                    );
                $(win.document.body).find( 'table' )
                    .addClass( 'compact' )
                    .css( 'font-size', 'inherit' );
            } }],
                dom: { button: {
                tag: "button",
                className: "btn btn-outline-dark btn-sm"
                    },
                        buttonLiner: {
                        tag: null
                    }
            }}
        }).columns.adjust();

        yadcf.init(attTable,
            [{
                column_number: 0,
                filter_type: "date",
                datepicker_type: 'daterangepicker',
                date_format: 'DD/MM/YYYY',
                filter_container_id: "dateContainer",
                // filter_default_label: ["From Date", "End Date"],
                filter_delay: 500, 
                moment_date_format: 'DD/MM/YYYY',
                style_class: 'form-control',
                // reset_button_style_class: false
                style_button_class: 'btn btn-outline-primary'
                // filter_plugin_options: {
                //     format: 'DD/MM/YYYY',
                //     showClear: true,
                //   }
            }],
            {
                cumulative_filtering: true
            }
        );

        // yadcf.exFilterColumn(hTable, [
        //     [0, {
        //         from: '01/01/2021',
        //         to: '31/12/2021'
        //     }],
        // ]);
    });
});