document.addEventListener('turbolinks:load', function() {
    var getExportFileName = document.getElementsByTagName("h3")[0];
    setTimeout(function() {
        $('#plain-table').DataTable({
            "autoWidth": true,
            "bScrollCollapse": true,
            "scrollX": true,
            "scrollY":"480px",
            "order": [0, 'desc'],
            "lengthMenu": [[30, 60, 90, -1], [30, 60, 90, "All"]],
            "dom": "<'row'<'form-control-md col-sm-9'f><'col-sm-3'>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-5'l><'col-sm-7'B>>" +
            "<'row'<'col-sm-6'i><'col-sm-6'p>>",
            buttons: { buttons: [{ extend: 'copy', className: 'copyButton' }, { extend: 'csv', className: 'csvButton', filename: function () { return getExportFileName;}, header: true }, { extend: 'print', className: 'printButton', customize: function ( win ) {
                $(win.document.body)
                    .css( 'font-size', '10pt' )
                    .prepend(
                        '<img src="/app/assets/images/geopedialogo.png" style="position:absolute; top:0; left:0;" />'
                    );
                $(win.document.body).find( 'table' )
                    .addClass( 'compact' )
                    .css( 'font-size', 'inherit' );
            } }],
                dom: { button: {
                tag: "button",
                className: "btn btn-outline-dark btn-sm"
                    },
                        buttonLiner: {
                        tag: null
                    }
            }}
        }).columns.adjust();
    
        //fungsi untuk filtering data berdasarkan tanggal 
        var start_date;
        var end_date;
        var DateFilterFunction = (function (oSettings, aData, iDataIndex) {
        var dateStart = parseDateValue(start_date);
        var dateEnd = parseDateValue(end_date);
        //Kolom tanggal yang akan kita gunakan berada dalam urutan 2, karena dihitung mulai dari 0
        //nama depan = 0
        //nama belakang = 1
        //tanggal terdaftar =2
        var evalDate= parseDateValue(aData[0]);
            if ( ( isNaN( dateStart ) && isNaN( dateEnd ) ) ||
                ( isNaN( dateStart ) && evalDate <= dateEnd ) ||
                ( dateStart <= evalDate && isNaN( dateEnd ) ) ||
                ( dateStart <= evalDate && evalDate <= dateEnd ) )
            {
                return true;
            }
            return false;
        });
    
        // fungsi untuk converting format tanggal dd/mm/yyyy menjadi format tanggal javascript menggunakan zona aktubrowser
        function parseDateValue(rawDate) {
            var dateArray= rawDate.split("/");
            var parsedDate= new Date(dateArray[2], parseInt(dateArray[1])-1, dateArray[0]);  // -1 because months are from 0 to 11   
            return parsedDate;
        }    
    
        $(document).ready(function() {
            //konfigurasi DataTable pada tabel dengan id example dan menambahkan  div class dateseacrhbox dengan dom untuk meletakkan inputan daterangepicker
            var $dTable = $('#member-table').DataTable({
                "autoWidth": true,
                // "sScrollX": "100%",
                // "sScrollXInner": "100%",
                "bScrollCollapse": true,
                "scrollX": true,
                "scrollY":"480px",
                "columnDefs" : [{"targets":0, "type":"date-eu"}],
                "order": [0, 'desc'],
                "lengthMenu": [[30, 60, 90, -1], [30, 60, 90, "All"]],
                "dom": "<'row'<'form-control-md col-sm-9'f><'col-sm-3' <'datesearchbox'>>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-5'l><'col-sm-7'B>>" +
                "<'row'<'col-sm-6'i><'col-sm-6'p>>",
                buttons: { buttons: [{ extend: 'copy', className: 'copyButton' }, { extend: 'csv', className: 'csvButton', filename: function () { return getExportFileName;}, header: true }, { extend: 'print', className: 'printButton', customize: function ( win ) {
                    $(win.document.body)
                        .css( 'font-size', '10pt' )
                        .prepend(
                            '<img src="/app/assets/images/geopedialogo.png" style="position:absolute; top:0; left:0;" />'
                        );
                    $(win.document.body).find( 'table' )
                        .addClass( 'compact' )
                        .css( 'font-size', 'inherit' );
                } }],
                    dom: { button: {
                    tag: "button",
                    className: "btn btn-outline-dark btn-sm"
                        },
                            buttonLiner: {
                            tag: null
                        }
                }}
            }).columns.adjust();
    
            var numberRenderer = $.fn.dataTable.render.number( '.', ',', 0, 'Rp. '  ).display;
    
            var $vTable = $('#data-table').DataTable({
                "footerCallback": function ( row, data, start, end, display ) {
                    var api = this.api(), data;
        
                    // Remove the formatting to get integer data for summation
                    var intVal = function ( i ) {
                        return typeof i === 'string' ?
                            i.replace(/[\Rp. ,]/g, '')*1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
        
                    // Total over all pages
                    total = api
                        .column( 7 )
                        .data()
                        .reduce( function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0 );
        
                    // Total over this page
                    pageTotal = api
                        .column( 7, { page: 'current'} )
                        .data()
                        .reduce( function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0 );
        
                    // Update footer
                    $( api.column( 7 ).footer() ).html(
                        numberRenderer(pageTotal) +' ('+ numberRenderer(total) +' total)'
                    );
                },
                "bScrollCollapse": true,
                "scrollX": true,
                "scrollY":"480px",
                "columnDefs" : [{"targets":0, "type":"date-eu"}],
                "order": [0, 'desc'],
                "lengthMenu": [[30, 60, 90, -1], [30, 60, 90, "All"]],
                "dom": "<'row'<'form-control-md col-sm-9'f><'col-sm-3' <'datesearchbox'>>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-5'l><'col-sm-7'B>>" +
                "<'row'<'col-sm-6'i><'col-sm-6'p>>",
                buttons: { buttons: [{ extend: 'copy', className: 'copyButton' }, { extend: 'csv', className: 'csvButton', filename: function () { return getExportFileName;}, header: true }, { extend: 'print', className: 'printButton', customize: function ( win ) {
                    $(win.document.body)
                        .css( 'font-size', '10pt' )
                        .prepend(
                            '<img src="/app/assets/images/geopedialogo.png" style="position:absolute; top:0; left:0;" />'
                        );
                    $(win.document.body).find( 'table' )
                        .addClass( 'compact' )
                        .css( 'font-size', 'inherit' );
                } }],
                    dom: { button: {
                    tag: "button",
                    className: "btn btn-outline-dark btn-sm"
                        },
                            buttonLiner: {
                            tag: null
                        }
                }}
            }).columns.adjust();
    
            var $xTable = $('#charge-table').DataTable({
                "footerCallback": function ( row, data, start, end, display ) {
                    var api = this.api(), data;
        
                    // Remove the formatting to get integer data for summation
                    var intVal = function ( i ) {
                        return typeof i === 'string' ?
                            i.replace(/[\Rp. ,]/g, '')*1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
        
                    // Total over all pages
                    total = api
                        .column( 8 )
                        .data()
                        .reduce( function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0 );
        
                    // Total over this page
                    pageTotal = api
                        .column( 8, { page: 'current'} )
                        .data()
                        .reduce( function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0 );
        
                    // Update footer
                    $( api.column( 8 ).footer() ).html(
                        numberRenderer(pageTotal) +' ('+ numberRenderer(total) +' total)'
                    );
                },
                "bScrollCollapse": true,
                "scrollX": true,
                "scrollY":"480px",
                "columnDefs" : [{"targets":0, "type":"date-eu"}],
                "order": [0, 'desc'],
                "lengthMenu": [[30, 60, 90, -1], [30, 60, 90, "All"]],
                "dom": "<'row'<'form-control-md col-sm-9'f><'col-sm-3' <'datesearchbox'>>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-5'l><'col-sm-7'B>>" +
                "<'row'<'col-sm-6'i><'col-sm-6'p>>",
                buttons: { buttons: [{ extend: 'copy', className: 'copyButton' }, { extend: 'csv', className: 'csvButton', filename: function () { return getExportFileName;}, header: true }, { extend: 'print', className: 'printButton', customize: function ( win ) {
                    $(win.document.body)
                        .css( 'font-size', '10pt' )
                        .prepend(
                            '<img src="/app/assets/images/geopedialogo.png" style="position:absolute; top:0; left:0;" />'
                        );
                    $(win.document.body).find( 'table' )
                        .addClass( 'compact' )
                        .css( 'font-size', 'inherit' );
                } }],
                    dom: { button: {
                    tag: "button",
                    className: "btn btn-outline-dark btn-sm"
                        },
                            buttonLiner: {
                            tag: null
                        }
                }}
            }).columns.adjust();
    
            
    
            //menambahkan daterangepicker di dalam datatables
            $("div.datesearchbox").html('<div class="input-group input-group-md mb-3"> <div class="input-group-prepend"> <span class="input-group-text"><i class="fas fa-calendar-alt"></i></span> </div><input type="text" class="form-control pull-right" id="datesearch" placeholder="Set Date"> </div>');
    
            // document.querySelectorAll('datesearchbox').style.textAlign = "right";
    
            //konfigurasi daterangepicker pada input dengan id datesearch
            $('#datesearch').daterangepicker({
                autoUpdateInput: false,
                locale: {
                    "cancelLabel": "Clear",
                    }
            });
    
            //menangani proses saat apply date range
            $('#datesearch').on('apply.daterangepicker', function(ev, picker) {
                $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
                start_date=picker.startDate.format('DD/MM/YYYY');
                end_date=picker.endDate.format('DD/MM/YYYY');
                $.fn.dataTableExt.afnFiltering.push(DateFilterFunction);
                    $dTable.draw();
                    $vTable.draw();
                    $xTable.draw();
            });
    
            $('#datesearch').on('cancel.daterangepicker', function(ev, picker) {
                $(this).val('');
                start_date='';
                end_date='';
                $.fn.dataTable.ext.search.splice($.fn.dataTable.ext.search.indexOf(DateFilterFunction, 1));
                    $dTable.draw();
                    $vTable.draw();
                    $xTable.draw();
                });
        }); 
     }, 100);
    
});