

// $('.hm_end').keyup(function(){
//     var hmstart;
//     var hmend;
// hmstart = parseFloat($('.hm_start').val());
// hmend = parseFloat($('.hm_end').val());
// var result = hmstart + hmend;
// $('.hmresult').val(result.toFixed(2));

$(document).on("change keyup blur", ".hm2", function(){

    var value1 = parseFloat($('.hm1').val());
    var value2 = parseFloat($('.hm2').val());
    var result = (value2-value1).toFixed(2);
    $('.hmresult').val(result);
});    

$(document).on("change", ".wh", function() {
    var sum = 0;
    $(".wh").each(function(){
        sum += +$(this).val();
    });
    $(".totalwh").val(sum);
});

$(document).on("change", ".st", function() {
    var sum = 0;
    $(".st").each(function(){
        sum += +$(this).val();
    });
    $(".totalst").val(sum);
});

$(document).on("change", ".bd", function() {
    var sum = 0;
    $(".bd").each(function(){
        sum += +$(this).val();
    });
    $(".totalbd").val(sum);
});

// $('.hmstart, .hmend').on('blur', function(e) {
//     var row = $(this).closest('tr');
//     var hm_start = $('.hmstart', row),
//         hm_end = $('.hmend', row),          
//         hm_result = $('.hmresult', row);
//     hmawal = parseFloat(hm_start.val());
//     hmakhir = parseFloat(hm_end.val());
//     if (!isNaN(hmawal) && !isNaN(hmakhir)) {
//         hm_result.text((hmakhir - hmawal).toFixed(2));
//     }
// });