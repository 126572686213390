// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
// require("admin-lte")
require("chartkick")
require("chart.js")

require("chartjs-plugin-datalabels")
require("moment")
require("tempusdominus-bootstrap-4")
require("select2")();
// require("select2-bootstrap4-theme")
require('datatables.net-bs4')
require('jszip')
require('datatables.net-buttons-bs4')
require('datatables.net-buttons')
require('daterangepicker')
require( 'datatables.net-datetime' )
require('highcharts')
require('yadcf')();
require('autonumeric')
// CSS
import "scss/site"

// JS
import "js/site"

// Importing
import "@fortawesome/fontawesome-free/js/all";
import "chartkick"
import "chartkick/chart.js"
import "select2"
import "yadcf"
import "autonumeric"

import Highcharts from "highcharts"

window.Highcharts = Highcharts

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// import $ from 'jquery';
// global.$ = jQuery;

// window.Rails = Rails