$(document).on("change", ".boulder", function() {
    var sum = 0;
    $(".boulder").each(function(){
        sum += +$(this).val();
    });
    $(".totalboulder").val(sum);
});

$(document).on("change", ".gabion", function() {
    var sum = 0;
    $(".gabion").each(function(){
        sum += +$(this).val();
    });
    $(".totalgabion").val(sum);
});

$(document).on("change", ".scrop", function() {
    var sum = 0;
    $(".scrop").each(function(){
        sum += +$(this).val();
    });
    $(".totalscrop").val(sum);
});

$(document).on("change", ".tanah", function() {
    var sum = 0;
    $(".tanah").each(function(){
        sum += +$(this).val();
    });
    $(".totaltanah").val(sum);
});

$(document).on("change", ".topsoil", function() {
    var sum = 0;
    $(".topsoil").each(function(){
        sum += +$(this).val();
    });
    $(".totaltopsoil").val(sum);
});

$(document).on("change", ".ob", function() {
    var sum = 0;
    $(".ob").each(function(){
        sum += +$(this).val();
    });
    $(".totalob").val(sum);
});